@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root {
    /* Prevents overflow on the x-axis */
    overflow-x: hidden;
}

/*  */
@layer components {
    .base-orange{
        @apply bg-orange-500
    }
    .base-orange-text{
        @apply text-orange-500
    }
    h1{
        @apply text-8xl;
    }
    h2{
        @apply text-5xl;
    }
    h3{
        @apply text-4xl;
    }
    h4{
        @apply text-3xl;
    }
    h5{
        @apply text-2xl;
    }
    h1,h2,h3,h4,h5{
        @apply text-gray-500 dark:text-white;
    }

    p{
        @apply text-xl  text-gray-500 dark:text-gray-300;
    }

    .mild-border{
        border: #d6d6d6 2px solid;
    }


    .bg-blur-dark{
        background-color:rgba(17, 24, 39,0.6);
        backdrop-filter: blur(8px);
    }
    .bg-blur-light{
        background-color:rgba(204, 204, 204,0.6);
        backdrop-filter: blur(8px);
    }
    .bg-blur-dark-2{
        background-color:rgba(17, 24, 39,0.6);
        backdrop-filter: blur(8px);
    }
    .bg-blur-light-2{
        background-color:rgba(204, 204, 204,0.6);
        backdrop-filter: blur(8px);
    }
    body {
        --scrollbar-track: initial;
        --scrollbar-thumb: initial;
        scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
        overflow: overlay;
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #cbd5e1; 
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #f97316; 
        border-radius: 10px;

      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #fb923c; 
      }
      


    /* For Removing number's input field spinner */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    .offerPrice{
        background: #f12711;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #e7a005, #f12711);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #e7a005, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
    .offerPrice-dark{
        background: #f12711;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #fecd62, #f12711);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #fecd62, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
  }